import $ from 'jquery';

import { AttemptGetter } from './getters';
import { problemModalState } from './modals/problemModalStore';
import { getApiUrl } from './problemCanvas';

import { AlertOptions, ProblemContext } from './index';

const DEFAULT_THEME_COLOR = '#5bc0de';

export function makeProblemContext(
  baseContext?: Partial<ProblemContext>,
  attemptGetters?: Map<number, AttemptGetter>
): ProblemContext {
  let timeSeenStart: Date;

  const alert = (dialog: AlertOptions): void => {
    problemModalState().alert(dialog);
  };

  const defaultProblemContext: ProblemContext = {
    getThemeColor: () => DEFAULT_THEME_COLOR,
    setLoading: (_loadingState: boolean) => {},
    isPermitReadAloud: () => false,
    isStudent: () => false,
    activity: () => true,
    getSS: () => undefined,
    getUsername: () => undefined,
    setCurrentUncertain: () => {},
    getGuid: () => undefined,
    getProblems: () => {},
    isTesting: () => false,
    isUseNativeKeyboard: () => false,
    isForceTouchKeyboard: () => false,
    errorToServer: (message: string) => {
      const username = baseContext?.getUsername?.() || 'unknown';
      const messageObject = {
        m: 'errorFromNonStudent, username ' + username + ': ' + message,
      };
      const url = getApiUrl() + '/ErrorToServer';

      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: url,
        data: messageObject,
        error: function (jqXHR) {
          console.error(
            'Error during errorToServer:',
            jqXHR.status,
            jqXHR.statusText,
            jqXHR.responseText
          );
        },
      });
    },

    attemptSubmitted: (data, agId) => {
      if (data.submitFail) {
        alert({
          msg: data.submitFail,
          top: 'Submit Failed',
        });

        return;
      }

      if (data.submitFail2) {
        alert({
          msg: data.submitFail2,
          reload: true,
          top: 'Submit Failed',
        });

        return;
      }

      if (data.probGenerationFail) {
        alert({
          msg: `GMM cannot create this type of problem! Please tell a friendly GMM person that ${data.probFailType} is broken.`,
          style: 'red',
          top: 'Technical Difficulty',
        });

        return;
      }

      if (!data.inv && !data.uF) {
        if (data.tC) {
          const attemptGetter = attemptGetters?.get(agId);

          if (attemptGetter) {
            attemptGetter.setStatus('c');
          }
        }
      }

      const messages = data.m;

      if (messages) {
        for (const x3 in messages) {
          if (typeof x3 === 'string') {
            alert({
              msg: messages[x3],
              top: 'Message',
            });
          }
        }
      }
    },
    harvestTimeSeen: () => {
      if (!timeSeenStart) {
        timeSeenStart = new Date();

        return 0;
      }

      const delta = new Date().getTime() - timeSeenStart.getTime();

      timeSeenStart = new Date();

      return delta;
    },
    processAjaxFailure: () => false,
    addStackableDialog: (dialog: AlertOptions) => {
      alert(dialog);
    },
  };

  return { ...defaultProblemContext, ...baseContext };
}
